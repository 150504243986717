<template>
  <div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h4>Dashboard</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="dashboard-stat widget-1">
            <div class="dashboard-stat-content">
              <router-link
                class="text-white"
                :to="{ name: 'my-listings.index' }"
              >
                <h4>{{ stats.total }}</h4>
                <span>Properties Uploaded</span>
              </router-link>
            </div>
            <div class="dashboard-stat-icon">
              <i class="ti-location-pin"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="dashboard-stat widget-2">
            <div class="dashboard-stat-content">
              <router-link
                class="text-white"
                :to="{ name: 'my-listings.index' }"
              >
                <h4>{{ stats.active }}</h4>
                <span>Active Properties</span>
              </router-link>
            </div>
            <div class="dashboard-stat-icon">
              <i class="ti-pie-chart"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="dashboard-stat widget-3">
            <div class="dashboard-stat-content">
              <h4>{{ stats.views }}</h4>
              <span>Property Views</span>
            </div>
            <div class="dashboard-stat-icon">
              <i class="ti-user"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-wraper">
        <div class="row">
          <div class="col-md-12">
            <h4>Information</h4>
          </div>
          <div class="col-md-6">
            <span>Email: </span>
            <strong>{{ user.email }}</strong>
            <img
              src="/assets/img/verified.png"
              alt="verified"
              height="15px"
              title="Verified"
              class="ms-2"
              v-if="user.email_verified"
            />
            <a
              href="#"
              class="d-block text-tiny text-muted"
              @click.prevent="verifyEmail"
              v-if="!user.email_verified"
              >Verify Now</a
            >
          </div>
          <div class="col-md-6">
            <span>Phone Number: </span>
            <strong>{{ user.phone_number }}</strong>
            <img
              src="/assets/img/verified.png"
              alt="verified"
              height="15px"
              title="Verified"
              class="ms-2"
              v-if="user.phone_number_verified"
            />
            <router-link
              class="text-tiny text-primary ms-3"
              :to="{ name: 'verify-phone' }"
              v-if="!user.phone_number_verified"
              >Verify Now</router-link
            >
          </div>
          <div class="col-md-12 mt-4">
            <p class="mb-0">Referral Link:</p>
            <referral-link />
          </div>
        </div>
      </div>
    </div>

    <loading v-if="loading" />

    <modal :show="email_sent" @close="email_sent = false">
      <template slot="header">Email Verification Sent</template>
      <div class="text-center">
        <img src="/img/email-verified.png" alt="verified" height="250px" />
        <div class="alert alert-success">
          We have sent an email to {{ user.email }}. Click on the link to
          complete email verification.
        </div>
        <a href="#" @click.prevent="email_sent = false" class="btn btn-primary"
          ><i class="lni lni-checkmark me-2"></i>Okay</a
        >
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      stats: {},
      user: {},
      loading: true,
      email_sent: false,
      show_add_agency: false,
      agency: {},
      agencies: [],
    };
  },

  methods: {
    submitAgency() {
      this.$axios
        .post("/api/v1/my-account/agencies", this.agency)
        .then((response) => {
          this.agencies.push(response.data.agency);
          this.show_add_agency = false;
        });
    },

    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/my-account").then((response) => {
        this.loading = false;
        this.stats = response.data.stats;
        this.user = response.data.user;
        this.agencies = response.data.agencies;
      });
    },

    verifyEmail() {
      this.$axios.post("/api/v1/send-verification-email").then(() => {
        this.email_sent = true;
      });
    },
  }
};
</script>
